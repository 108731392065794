<template>
  <div class="common-bg">
    <van-nav-bar title="视频"  left-arrow
      :border="false"
      @click-left="onClickLeft"/>

  <video  id="media" class="niubi" :src="video" controls="controls"   muted autoplay="autoplay" ></video>
 <van-button type="warning" block @click="onSubmit()">
        领取收益
      </van-button>
  </div>
</template>

<script>
import List from './components/List'
import { getProductList, getMessageList,getVideoList,videoSendScore } from '@/utils/api.js'
export default {
  name: 'video',
  components: { List },
  data() {
    return {
      show: false,
      config: {},
      tabsActive: '1',
      stockList: [],
      fundList: [],
      msgList: [],
       video:"",
    }
  },
  created() {
    this.getStock()
    this.getFund()

  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    goTo(to) {
      this.$router.push(to)
    },
    dialog() {
      this.$toast('维护中')
    },
    // 获取股权列表
    getStock() {
      getProductList(1).then((res) => {
        this.stockList = res.data
      })
    },
    //领取收益
       onSubmit() {


         this.$toast.loading({
           duration: 0,
           message: '提交中...',
           forbidClick: true
         })
         setTimeout(() => {
           videoSendScore().then((res) => {
             this.$toast.clear()
             if (res.code === 200) {
              // window.location.href = res.message
               this.$toast(res.message)
                return
             } else {
               this.$toast(res.message)
               if(res.message === '请先实名') this.$router.push('/home/realName')
             }
           })
         }, 1700)
       },
  // 获取基金列表
  getFund() {
    getVideoList().then((res) => {
      this.video = res.data.video
    })
  },
    // 获取消息列表
    getMsg() {
      getMessageList().then((res) => {
        this.msgList = res.data.splice(0, 5)

        let type = localStorage.getItem('hometype')
        if (type === 'login' && this.msgList[0].status != 1) {
          this.showDialogTip()
          localStorage.removeItem('hometype')
        }
      })
    },
    showDialogTip() {
      let msg = this.msgList[0]
      this.$dialog
        .alert({
          title: msg.title,
          message: msg.content,
          theme: 'round-button'
        })
        .then(() => {})
    }
  }
}
</script>
<style scoped lang="scss">
.home {
  background: url(~@/assets/images/bg.png) center no-repeat;
  background-size: 100% 100%;
  /*height: 100vh;*/
  font-size: 14px;
  min-height: 100%;
  .cate {
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    width: 100px;
    background: #fff;
    margin: 10px 6px;
    padding: 10px 5px;
    img {
      width: 24px;
      height: 25px;
    }
  }
}
.qwe {
  width: 100%;
  height: 375px;
  background: #ff6700;
}
.titleimg{
  width: 70%;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.dian{
  border-radius: 50%;
  background-color: #ff6700;
  width: 10px;
  height: 10px;
  margin-left: 18px;
}
.line{
  border-left:2px solid #ff6700;
  width: 90%;
  height: 250px;
 margin-left: 22px;
 margin-top: 5px;
 margin-bottom: 5px;
  .time{
    position: absolute;
    z-index: 2;
    margin-top: -17px;
    margin-left: 20px;
    font-size: 15px;
    font-weight: bold;
  }}
 .ex{
   width: 85%;
   height: 160px;
   background-color: #fff;
   border-radius: 10px;
   display: flex;
position: absolute;
margin-top: -225px;
margin-left: 30px;
   .titimg{
    margin-top: 30px;
    margin-left: 20px;
    border-radius: 10px;
   }
   .title{
     font-size: 20px;
     font-weight: bold;
     margin-left: 150px;
     margin-top: 30px;
     position: absolute;
   }
   .xl{
     position: absolute;
     margin-left: 150px;
     margin-top: 80px;
     background-color:  #ff6702;
     width: 37px;
     height: 20px;
     border-radius: 2px;
     text-align: center;
     color: #fff;
   }
   .num{
     position: absolute;
    margin-left: 185px;
    margin-top: 80px;
    background-color:  #ffe1cc;
    width: 60px;
    height: 20px;
    border-radius: 2px;
    text-align: center;
    color: #ff6702;
   }
   .price{
     position: absolute;
     margin-left: 150px;
     margin-top: 110px;
     font-size: 20px;
     font-weight: bold;
   }
 }
  .niubi{
    width: 350px;
    height: 300px;
    margin-left: 5px;
  }
</style>
